import React, { useState } from 'react';
import { stockyApi } from '../misc/StockyApi';
import SellersSelect from '../sellers/SellersSelect'; // Componente previamente definido

function MigrateItems(user) {
  const [query, setQuery] = useState('');
  const [seller, setSeller] = useState('');
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [page, setPage] = useState(1);

  const handleSearch = async () => {
    try {
      const response = await stockyApi.searchItems(user, { query, seller, page });
      setItems(response.data.items); // Datos mock del backend
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const toggleItemSelection = (itemId) => {
    setSelectedItems((prev) =>
      prev.includes(itemId) ? prev.filter((id) => id !== itemId) : [...prev, itemId]
    );
  };

  const copyItem = (itemId) => {
    console.log(`Copy item with ID: ${itemId}`);
  };

  const copyItemsToAccount = () => {
    console.log('Copying items to another account:', selectedItems);
  };

  return (
    <div>
      <h1>Buscar Ítems</h1>
      <div>
        <input
          type="text"
          placeholder="Buscar ítems por nombre"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button onClick={handleSearch}>Buscar</button>
        <SellersSelect onChange={setSeller} />
      </div>

      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) => {
                  setSelectedItems(
                    e.target.checked ? items.map((item) => item.id) : []
                  );
                }}
              />
            </th>
            <th>Title</th>
            <th>SKU</th>
            <th>Seller</th>
            <th>Stock</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <React.Fragment key={item.id}>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(item.id)}
                    onChange={() => toggleItemSelection(item.id)}
                  />
                </td>
                <td>{item.title}</td>
                <td>{item.sku}</td>
                <td>{item.seller}</td>
                <td>{item.stock}</td>
                <td>
                  <button onClick={() => copyItem(item.id)}>Copiar</button>
                </td>
              </tr>
              <tr>
                <td colSpan="6">
                  <div className="collapsed-content">
                    {item.masterListings.map((listing) => (
                      <div key={listing.id}>
                        <a href={listing.marketplaceUrl}>{listing.title}</a>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <button onClick={copyItemsToAccount}>Copiar ítems seleccionados</button>
    </div>
  );
};

export default MigrateItems;

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './components/context/AuthContext';
import Navbar from './components/misc/Navbar';
import Home from './components/home/Home';
import Login from './components/home/Login';
import Signup from './components/home/Signup';
import NewAccount from './components/account/NewAccount';  // Import the new component
import PublishItem from './components/items/PublishItem';
import MigrateItems from './components/migrateitems/MigrateItems';

function App() {
  return (
      <AuthProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path='/home' element={<Home />} />
            <Route path='/migrateItems' element={<MigrateItems />} />
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/newAccount' element={<NewAccount />} />
            <Route path='/publishItem' element={<PublishItem />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </AuthProvider>
  );
}

export default App;
